import React from 'react'
import type {GetStaticPaths, GetStaticProps} from 'next'
import PageContainer from 'src/components/layout/PageContainer'
import {DatocmsContent} from 'src/components/content/DatocmsContent'
import {datocms} from 'src/api/datocms'
import {trim} from 'lodash'
import {Loader} from 'src/components/common'
import {useRouter} from 'next/router'
import {
  allPagePathsByCategoryQuery,
  pageContentQuery,
  pageQuery,
} from 'src/api/datocms/queries'
import type {Page, Page_page as PageType} from 'src/api/datocms/types/Page'
import type {QueryListenerOptions} from 'react-datocms'
import {DEFAULT_REVALIDATE_TIME} from 'src/const'
import NotFound from 'src/components/layout/NotFound'
import {
  makeSubscriptionProps,
  usePageContentSubscription,
} from '../api/datocms/utils'

interface PageParams {
  path: string[]
}

interface Props {
  subscription: QueryListenerOptions<Page, {path: string}>
}

export const getStaticPaths: GetStaticPaths = async () => {
  const {data} = await datocms().query({
    query: allPagePathsByCategoryQuery,
    variables: {
      category: null,
    },
  })

  return {
    paths: data?.allPages.map(
      ({path}: PageType) => `/${trim(path || '', '/')}`,
    ),
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = false,
}) => {
  const {path} = (params as unknown) as PageParams
  const pathString = path.join('/')

  return {
    props: {
      pageSubscription: await makeSubscriptionProps(preview, pageQuery, true),
      subscription: await makeSubscriptionProps(
        preview,
        pageContentQuery,
        {
          path: `/${pathString}`,
        },
        true,
      ),
    },
    revalidate: DEFAULT_REVALIDATE_TIME,
  }
}

export default function DynamicPage({subscription}: Props): JSX.Element {
  const router = useRouter()
  const isSubscriptionDisabled = router.isFallback || !subscription
  const subscriptionData = isSubscriptionDisabled
    ? {enabled: false as const}
    : subscription
  const {data} = usePageContentSubscription(subscriptionData)
  if (isSubscriptionDisabled) {
    return <Loader />
  }
  const page = data?.page
  const title = !page ? 'Síða fannst ekki' : data?.page?.heading ?? ''
  return (
    <PageContainer title={`${title} | Rekstrarvörur `}>
      {!page ? <NotFound /> : <DatocmsContent content={page.content} />}
    </PageContainer>
  )
}
